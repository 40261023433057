









import { Component } from 'vue-property-decorator';
import ChartBase from '../ChartBase';
import CardChartData from './modelsCard';

@Component
export default class ChartCard extends ChartBase {
  declare chartData: CardChartData;

  label = '';

  value = '';

  onInitOrUpdate(): void {
    console.log('init or update');

    if (!this.chartData.valueField) return;
    this.label = this.chartData.label;
    this.value = this.queryResult[0][this.chartData.valueField];
  }
}
